<template>
  <div class="container">
    <h1 style="text-align: center">
      Witaj na fotoregiony.pl
    </h1>
    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="sign-up">
          <h2 class="header">
            Mam konto
          </h2>
          <SignInForm redirect-on-success="checkout-payment" />
        </div>
      </div>
      <div class="col-12 offset-lg-1 col-lg-5">
        <div class="sign-up">
          <h2 class="header">
            Nie mam konta
          </h2>
          <p>
            <router-link
              class="button"
              :to="{
                name: 'sign-up',
                query: { redirectTo: 'checkout-payment' },
              }"
            >
              Załóż konto
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignInForm from '@/vue/components/auth/sign-in-form.vue';

export default {
  name: 'SignUp',

  components: { SignInForm },
};
</script>
